@keyframes bounceInRight{
    0%,60%,75%,90%,
    to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    0% {
        opacity: 0;
        transform: translate3d(3000px,0,0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px,0,0);
    }
    75% {
        transform: translate3d(10px,0,0);
    }
    90% {
        transform: translate3d(-5px,0,0);
    }
    to {
        transform: translateZ(0);
    }
}

.bounceInRight {
    animation-name: bounceInRight;
}
