.gallery {
    max-width: 692px;

    &__main-photo {
        max-height: 429px;
        margin-bottom: 14px;

        img {
            width: 100%;
        }
    }

    &__arrow {
        width: 29px;
        height: 69px;
        background-color: #2a2a2a;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: $primary-color-active;
        }
    }

    &__icon {
        @include flex(row, wrap, center, center, center);
        color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 14px;
    }

    &__thumbnails {
        height: 69px;
        margin: 0px 5px;

        img {
            width: 110px;
            height: 69px;    
            margin-right: 13.6px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }
}

@media (max-width: $lg)  {

    .gallery {
    
        &__thumbnails {
            width: calc(100% - 70px);
            overflow: hidden;
        }
    }
}