@-webkit-keyframes fly {
    0% {
        transform: translate(-50px, 0px) rotate(5deg) scale(1);
    }
    50% {
        transform: translate(-100px, 50px) rotate(0deg) scale(1.5);
    }
    100% {
        transform: translate(10px, 100px) rotate(-5deg) scale(1);
    }
}

@keyframes fly {
    0% {
        transform: translate(-50px, 0px) rotate(5deg) scale(1);
    }
    50% {
        transform: translate(-100px, 50px) rotate(0deg) scale(1.5);
    }
    100% {
        transform: translate(10px, 100px) rotate(-5deg) scale(1);
    }
}