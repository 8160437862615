.subscription {
    background-image: url(../../assets/newsletter.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 49px 0 50px;
    min-height: 328px;
    &__caption {
        color: #ffffff;
    }
    &__form {
        max-width: 546px;
        margin: 0 auto;
    }
}