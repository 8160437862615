.form-subscription {
    position: relative;    
    border-radius: 2.9px;
    overflow: hidden;    

    &__control {
        padding: 16px 132px 17px 15px;
        width: calc(100% - 147px);
    }

    &__btn {
        position: absolute;
        top: 0px;
        right: 0px;    
        height: 100%;    
        width: 132px;
        background-color: $primary-color;

        &:hover {
            background-color: $primary-color-active;
            color: #ffffff;
        }
    }
}

@media (max-width: $md)  {

    .form-subscription {
        min-height: initial;
        padding: 40px 0px;
    
        &__control {
            padding: 15px;
            margin-bottom: 15px;
            width: 100%;
        }
    
        &__btn {
            position: relative;  
            width: 100%;
        }
    }
}