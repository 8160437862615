.form-contact {

    &__control {
        box-shadow: 0.5px 0.9px 1px 0 rgba(22,22,23,.09);
        border-radius: 0;
        height: 44px;
        width: 100%;
        padding: 0px 10px;
        margin: 0px 0 19px;
    }

    &__area {
        height: 126px;
        padding: 10px;    
        margin-bottom: 13px;
        max-height: 126px;
        width: 100%;
    }

    &__btn {
        width: 100%;
        color: #fefefe;
        background-color: $primary-color-active;

        &:hover {
            background-color: $second-color;
            color: $main-color-family;
        }
    }
}