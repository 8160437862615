.stars {
    list-style: none;    
  
    @for $i from 1 through 130 {
        $counter: 50*$i;
        
        li:nth-of-type(#{$i}){
            animation-delay: #{-$counter}s;
            animation-duration: #{$counter * .02}s;
            top: random(600) + px;
            left: random(1400) + px;
        }
    }
    
    li {
        animation-name: stars;
        animation-direction: normal;
        animation-iteration-count: infinite;
        height: 2px;
        width: 2px;
        background: white;
        position: absolute;
        border-radius: 50%;
        box-shadow: 0px 0px 20px 0px white;
        left: 10px;
        top: 10px;
        z-index: 0;
    }
}

@-webkit-keyframes stars {
    0% {
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(-100vw);
    }
}

@keyframes stars {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}