@keyframes bounceInUp{
    0%,60%,75%,90%,
    to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0,3000px,0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0,-20px,0);
    }
    75% {
        transform: translate3d(0,10px,0);
    }
    90% {
        transform: translate3d(0,-5px,0);
    }
    to{
        transform: translateZ(0);
    }
}

.bounceInUp {
    animation-name: bounceInUp;
}