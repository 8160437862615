@-webkit-keyframes flicker {
    0% {
        transform: rotate(40deg) translate(10px, -10px);
        opacity: 0.7;
    }
    20% {
        transform: rotate(35deg) translate(20px, -20px);
        opacity: 1;
    }
    40% {
        transform: rotate(45deg) translate(15px, -15px);
        opacity: 0.7;
    }
    60% {
        transform: rotate(35deg) scale(1.02) translate(10px, -10px);
        opacity: 1;
    }
    80% {
        transform: rotate(45deg) scale(0.9) translate(5px, -5px);
        opacity: 0.7;
    }
    100% {
        transform: rotate(40deg) translate(0px, 0px);
        opacity: 1;
    }
}

@keyframes flicker {
    0% {
        transform: rotate(40deg) translate(10px, -10px);
        opacity: 0.7;
    }
    20% {
        transform: rotate(35deg) translate(20px, -20px);
        opacity: 1;
    }
    40% {
        transform: rotate(45deg) translate(15px, -15px);
        opacity: 0.7;
    }
    60% {
        transform: rotate(35deg) scale(1.02) translate(10px, -10px);
        opacity: 1;
    }
    80% {
        transform: rotate(45deg) scale(0.9) translate(5px, -5px);
        opacity: 0.7;
    }
    100% {
        transform: rotate(40deg) translate(0px, 0px);
        opacity: 1;
    }
}