.footer {
    padding: 78px 0px 122px;

    &__title {
        font-family: $font-stack;
        font-weight: bold;
        color: $main-color-family;
        text-transform: uppercase;    
        font-size: 10px;
        margin-bottom: 20px;
        letter-spacing: -0.2px;
    }

    &__list {
        list-style: none;

        &--col {
            @include flex(row, wrap, space-around, flex-start, stretch);
        }
 
        &--row {
            @include flex(column, nowrap, flex-start, stretch, stretch);
        }
    }

    &__social {
        width: 113px;
        margin: 0 auto;    
    }
}

@media (max-width: $md)  {

    .footer {
        padding: 40px 0px;    

        &__title {
            margin-bottom: 15px;
        }

        &__item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
