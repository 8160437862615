.form {

    &__control {
        background-color: #fefefe;
        border: 0px;            
        font-family: $font-stack;
        font-size: 19px;
        line-height: 1;

        &::placeholder { 
            color: #d8d8d8;
        }
    }

    &__label {
        font-size: 10px;
        font-weight: bold;
        font-family: $font-stack;
        color: #323232;
        text-transform: uppercase;
        margin-left: -1px;
    }

    &__area {
        min-height: 40px;
        max-width: 100%;
    }
}

 
 