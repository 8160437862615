.social {

    &__link {
        color: #000000;
        text-decoration: none;
        font-size: 20px;
        
        &:hover {
            color: $primary-color-active;

            .social__icon {
                transform: rotateX(360deg);
            }
        }
    }

    &__icon {
        padding: 8px;
        @extend %animation;
    }
}
