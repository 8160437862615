.video {
    padding: 80px 0px 114px;
    overflow: hidden;

    &__slider {
        
    }
}

@media (max-width: $md)  {

    .video {
        padding: 40px 0px;    
    }
}