.rating {

    &__progress {
        appearance: none;
        border: none;
        width: 100%;
        height: 15px;
        background-color: white;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .5) inset;
        color: royalblue;
        position: relative;
        margin: 0 0 10px;

        &::-webkit-progress-bar {
            background-color: white;
            box-shadow: 0 0 1px 1px rgba(0,0,0,.5) inset;
        }

        &::-webkit-progress-value {
            position: relative;
            background-size: 35px 20px, 100% 100%, 100% 100%;
            background-image: -webkit-linear-gradient( 135deg, transparent, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, .1) 66%, transparent 66%), -webkit-linear-gradient( top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .2)), -webkit-linear-gradient( left, #09c, #f44);
        }
    }

    &__value {
        position: relative;
        color: #fff;
        top: -29px;

        &:after {
            content: attr(data-value) '%';
            position: absolute;
            right: 1px;
            top: -2px;    
            font-size: 11px;
        }
    }
}