.title {
    font-family: $font-stack;
    font-size: 43px;
    font-weight: bold;
    color: $main-color-family;    
    letter-spacing: 0.1px;
    margin-bottom: 37px;

    &--invert {
        color: invert($main-color-family);
    }
}

@media (max-width: $lg)  {

    .title {
        font-size: 37px;
        margin-bottom: 30px;
    }
}

@media (max-width: $md)  {

    .title {
        font-size: 27px;
        margin-bottom: 15px;
    }
}